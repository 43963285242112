import * as React from 'react'
import { container } from './container.module.css'

const Container = ({ maxWidth, children }) => {
  maxWidth = maxWidth ? maxWidth : 1230

  return (
    <div style={{ maxWidth: maxWidth }} className={container}>
      {children}
    </div>
  )
}

export default Container