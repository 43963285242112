import * as React from "react"
import Grid from '@mui/material/Grid'
import { StaticImage } from 'gatsby-plugin-image'

function News() {


  return (
    <>
      <Grid container spacing={10} alignItems="center" direction="row-reverse">
        <Grid item sm={7}>
          <h2>Happyriders-Cup</h2>
          Fii (devino) ciclistul suprem si castiga Casca de Aur!<br />
          Participa la marea competitie si lupta pentru recompense!<br />
          Cel mai bun ciclist al zilei va primi 5 Euro.<br />
          La sfarsitul fiecarei luni, selectam ciclistul campion!<br />
          Pentru detalii suplimentare, apelati la managerul HappyRiders.
        </Grid>
        <Grid item sm={5}>
          <StaticImage
            alt="Be a happy rider!"
            src="../images/ranks.png"
          />
        </Grid>
      </Grid>
    </>
  )
}

export default News
