import * as React from 'react'

const Section = ({ id, paddingTop, paddingBottom, children }) => {
  paddingTop = paddingTop ? paddingTop : 75
  paddingBottom = paddingBottom ? paddingBottom : 75

  return (
    <section id={id} style={{ paddingTop: paddingTop, paddingBottom: paddingBottom }}>
      {children}
    </section>
  )
}

export default Section