import * as React from 'react'
import Layout from '../components/layout'
import Section from '../components/section'
import Container from '../components/container'
import Seo from '../components/seo'
import { StaticImage } from 'gatsby-plugin-image'
import * as HeroCSS from '../components/hero.module.css'
import * as HomeCSS from '../components/home.module.css'
import Faq from 'react-faq-component'
import faq from '../components/faq.config'
import News from '../components/news'
import Calculator from '../components/calculator'
import Grid from '@mui/material/Grid'

const IndexPage = () => {
  return (
    <Layout>

      <div className={HeroCSS.hero}>
        <h1>Jobul vieții tale!</h1>
        <StaticImage
          className={HeroCSS.heroImageWrapper}
          alt="Be a happy rider!"
          src="../images/hero-home.jpg"
        />
      </div>

      <Section paddingTop={150}>
        <Container>
          <Grid container spacing={10} alignItems="center" direction="row-reverse">
            <Grid item sm={5}>
              <StaticImage
                alt="Be a happy rider!"
                src="../images/rider-illu.png"
              />
            </Grid>
            <Grid item sm={7}>
              <h2>Susține flota noastră și devino curier pe bicicletă!</h2>
              <h3>Finanțăm cazarea, transferul si echipamentul!</h3>
              <p>
                Veți merge cu una din bicicletele noastre de top în marile orașe din Austria (Viena, Graz, Salzburg...), colaborând cu restaurantele locale pentru a livra mancare clienților.
              </p>
            </Grid>
          </Grid>
        </Container>
      </Section>

      <Section>
        <Container>
          <Grid container spacing={10}>
            <Grid item sm={7}>
              <Calculator />
            </Grid>
            <Grid item sm={5}>
              Hier könnten wir zusätzliche Infos hinschreiben um es dem user zu erleichtern seine Bewerbung abzusenden wie zB den Ablauf anteasern etc...
            </Grid>
          </Grid>
        </Container>
      </Section>

      <Section id="news">
        <Container>
          <News />
        </Container>
      </Section>

      <Section id="faq">
        <Container>
          <Grid container spacing={10} direction="row-reverse">
            <Grid item sm={7}>
              <Faq data={faq} />
            </Grid>
            <Grid item sm={5}>
              <StaticImage
                alt="Be a Happy rider!"
                src="../images/rainrider_faq.jpg"
                className='mobileFull'
              />
            </Grid>
          </Grid>
        </Container>
      </Section>
    </Layout >
  )
}

export const Head = () => <Seo title="Home" />

export default IndexPage