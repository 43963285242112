import * as React from 'react'

const Currency = ({ children }) => {

    return (
        <>
            {new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(children)}
        </>
    )
}

export default Currency