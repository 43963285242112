const faq = {
    title: "FAQ",
    rows: [
        {
            title: "Ce fel de slujbă este aceasta?",
            content: "Este vorba despre livrarea de alimente. Din ce în ce mai mulți oameni își primesc mâncarea la domiciliu. Oamenii iau comenzi de la restaurante și supermarketuri și apoi le livrează la casele particulare cu o bicicletă electronică."
        },
        {
            title: "Unde se află locul de muncă?",
            content: "Lucrăm în marile orașe din Austria. În prezent, suntem activi în Viena, Graz, Salzburg, Linz și St. Pölten."
        },
        {
            title: "Ce primesc organizat de HappyRiders?",
            content: "Tot ce aveți nevoie! Vă organizăm cazarea, e-bike, powerbank pentru telefonul mobil, căști, cartela sim cu volum de date în AT, geacă rezistentă la intemperii, cască de biciclist, călătoria, înregistrarea reședinței temporare, înregistrarea la securitatea socială, înregistrarea în sistem, administrarea registrelor de serviciu și multe altele..."
        },
        {
            title: "Cum voi fi cazat?",
            content: "Apartamentele sunt întotdeauna pentru 2-4 persoane și constau dintr-un dormitor, o baie + WC și un salon cu bucătărie. Sunteți bineveniți să solicitați fotografii de probă de la noi. Cazarea variază ușor în funcție de locul de muncă, dar toate au WIFI."
        },
        {
            title: "Locuințele sunt curate?",
            content: "O dată pe săptămână, echipa noastră de curățenie curăță și face ordine în locuințe. După aceea, totul trebuie să fie păstrat cât mai curat posibil până la următoarea curățare."
        },
        {
            title: "Ce trebuie să iau cu mine?",
            content: "Cele mai multe lucruri sunt furnizate de noi, dar unele trebuie să le aduceți singuri: <ul><li>Un smartphone funcțional și modern (este necesar GPS!).</li> <li>Un schimb de haine.</li><li>Lenjerie de pat (este nevoie doar de o pătură!)</li><li>Articole de toaletă (gel de duș, șampon, pastă și periuță de dinți etc.)</li><li>Încălțăminte bună.</li><li>Și niște bani pentru primele zile, până când începeți să lucrați.</li></ul>"
        },
        {
            title: "Care este procedura?",
            content: "Veți primi procedura exactă de la noi după ce vom primi cererea dumneavoastră."
        },
        {
            title: "Poate cineva să facă acest lucru?",
            content: "În principiu, da, bineînțeles că ajută dacă ești sportiv! ;)"
        },
        {
            title: "Cât de mult pot câștiga?",
            content: "Puteți vedea acest lucru exact din calculatorul nostru de mai sus."
        },
        {
            title: "Cum pot ajunge la locul de muncă?",
            content: "Vă oferim un transfer cu microbuzul direct la cazarea dumneavoastră în Austria. Personalul nostru vă va informa cu privire la datele și punctul de plecare."
        },
        {
            title: "Cât timp pot lucra?",
            content: "Cât timp doriți. Cu toate acestea, șederea minimă este de 30 de zile de la finalizarea formării."
        },
        {
            title: "Câte ore trebuie să lucrez?",
            content: "Noi creăm pentru dumneavoastră listele de sarcini pentru 60 de ore pe săptămână. Asta înseamnă 6 zile de 10 ore pe săptămână. Cu toate acestea, sunteți binevenit să lucrați mai mult."
        },
        {
            title: "Cum sunt înregistrat?",
            content: "Lucrați în Austria în calitate de lucrător independent/liber-profesionist. Acest lucru înseamnă că o factură este emisă pe numele dumneavoastră și că puteți lucra oricât de mult doriți."
        },
        {
            title: "Sunt asigurat?",
            content: "Da, bineînțeles. În Austria, există o lege privind asigurarea obligatorie. Acest lucru înseamnă că sunteți asigurat pe deplin în Austria din prima zi."
        },
        {
            title: "Cine plătește asigurarea?",
            content: "Asigurarea dumneavoastră este plătită de angajator. Nu trebuie să plătiți nimic separat pentru această asigurare."
        },
        {
            title: "Trebuie să plătesc impozit?",
            content: "Depinde de cât de mult câștigi. În prezent, suma scutită de impozit este de 11.000 de euro pe an. Acest lucru înseamnă că tot ceea ce câștigați până la 11.000 de euro pe an este scutit de impozit în Austria."
        },
        {
            title: "Ce se întâmplă dacă sunt bolnav sau dacă nu mai pot sau nu mai doresc să lucrez?",
            content: "Bineînțeles, veți fi plătit cu ceea ce ați câștigat până acum. Cu toate acestea, costurile fixe suportate, cum ar fi cazarea, transferul și închirierea de biciclete, vor fi deduse: Cazarea, transferul, închirierea de biciclete vor fi deduse. Restul se plătește."
        },
        {
            title: "Cum voi fi plătit?",
            content: "Vă puteți primi banii prin transfer bancar în contul dumneavoastră sau în numerar."
        },
        {
            title: "Când voi fi plătit?",
            content: "Întotdeauna vă primiți banii între 7 și 10 ale lunii.  De exemplu: începeți la 15.01. și lucrați până la 22.02. În acest caz, veți primi banii de la 15.01-31.01 între 07.02 și 10.02. Banii din perioada 01.02-22.02 vor fi plătiți între 07.03 și 10.03."
        },
        {
            title: "Pot să primesc avansuri?",
            content: "Ba da, puteți. La HappyRiders vă oferim întotdeauna avansuri. Dacă ați lucrat deja câteva zile, puteți să ne contactați și vă vom plăti o parte din onorariu în avans."
        }]
}

module.exports = faq