const calculatorConfigs = {
    transfer: 140,
    workingHours: 250,
    earningPerDelivery: 0,
    deliveriesPerHour: {
        rangeFrom: 2.2,
        rangeTo: 3.4
    },
    tipPerDelivery: 0.7,
    commission: [{
        rangeFrom: 0,
        rangeTo: 2000,
        percentage: 20
    }, {
        rangeFrom: 2000,
        rangeTo: 3000,
        percentage: 10
    }, {
        rangeFrom: 3000,
        rangeTo: '',
        percentage: 5
    }],
    provider: {
        mjam: {
            earningPerDelivery: 5,
            languages: [{
                name: 'de',
                langMinLevel: 50,
            }]
        },
        velofood: {
            earningPerDelivery: 4,
            languages: [{
                name: 'de',
                langMinLevel: 50,
            }, {
                name: 'en',
                langMinLevel: 50,
            }]
        }
    },
    expenses: [{
        label: 'Accomodation',
        value: 300
    }, {
        label: 'Insurance',
        value: 150
    }, {
        label: 'E-Bike',
        value: 140
    }, {
        label: 'Simcard + Powerbank',
        value: 30
    }, {
        label: 'Organisation-Fee',
        value: 100
    }]
}

const languageLevelMarks = [
    {
        value: 0,
        label: '',
    },
    {
        value: 16.67,
        label: 'A1',
    }, {
        value: 33.33,
        label: 'A2',
    }, {
        value: 50,
        label: 'B1',
    }, {
        value: 66.67,
        label: 'B2',
    }, {
        value: 83.33,
        label: 'C1',
    }, {
        value: 100,
        label: 'C2',
    }
]

const workIntensityMarks = [
    {
        value: 0,
        label: 'Low'
    }, {
        value: 50,
        label: 'Medium'
    }, {
        value: 100,
        label: 'High'
    }]

const languageNames = {
    A1: 'Complete Beginner',
    A2: 'Elementary',
    B1: 'Lower-Intermediate',
    B2: 'Upper-Intermediate',
    C1: 'Advanced',
    C2: 'Fluent',
}

module.exports = {
    calculatorConfigs,
    languageLevelMarks,
    workIntensityMarks,
    languageNames
}