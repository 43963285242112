import * as React from 'react'
import { calculatorConfigs, languageLevelMarks, workIntensityMarks, languageNames } from './calculator.config'
import Button from '@mui/material/Button'
import Slider from '@mui/material/Slider'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import Box from '@mui/material/Box'
import Currency from './currencyFormatter'
import LinearProgress from '@mui/material/LinearProgress'
import { StaticImage } from 'gatsby-plugin-image'
import TextField from '@mui/material/TextField'
import axios from 'axios'
import * as mailValidator from 'email-validator'
import Alert from '@mui/material/Alert'
import LoadingButton from '@mui/lab/LoadingButton'
import SendIcon from '@mui/icons-material/Send'

import '@fontsource/roboto/300.css'
import '@fontsource/roboto/400.css'
import '@fontsource/roboto/500.css'
import '@fontsource/roboto/700.css'
import './calculator-styles.sass'

const Calculator = () => {

    const steps = {
        calculator: 0,
        result: 1,
        applyNow: 2,
        thx: 3
    }

    const [calculatorConfig, setCalculatorConfig] = React.useState(calculatorConfigs)

    const [calculator, setCalculator] = React.useState({
        languageLevel: {
            de: {
                value: 0,
                label: 'No German'
            },
            en: {
                value: 0,
                label: 'No English'
            }
        },
        workIntensity: 50
    })
    const [resultState, setResultState] = React.useState({
        totalEarnings: 0,
        totalExpenses: 0,
        nettoEarnings: 0
    })
    const [stepState, setStepState] = React.useState(steps.calculator)
    const [candidate, setCandidate] = React.useState({
        name: '',
        phone: '',
        mail: ''
    })
    const [calculationDisabled, setCalculationDisabled] = React.useState(true)
    const [formSent, setFormSent] = React.useState(false)
    const [loading, setLoading] = React.useState(false)
    const [nameError, setNameError] = React.useState(null)
    const [mailError, setMailError] = React.useState(null)
    const [phoneError, setPhoneError] = React.useState(null)

    React.useEffect(() => {
        // bad approach, it sometimes flickers because it gets rendered 4 times but it works
        setEarningPerDelivery()
        if (calculatorConfig.earningPerDelivery === 0) {
            setCalculationDisabled(true)
        } else {
            setCalculationDisabled(false)
        }
    }, [calculatorConfig])

    const handleLanguageChange = (value, language) => {
        let languageLabel = getLanguageLabel(value, language)

        setCalculatorConfig(calculatorConfig => ({
            ...calculatorConfig,
            earningPerDelivery: 0
        }))
        setEarningPerDelivery()

        let newCalculator = { ...calculator }
        newCalculator.languageLevel[language].value = value
        newCalculator.languageLevel[language].label = `${languageLabel} (${Object.keys(languageNames).find(key => languageNames[key] === languageLabel)})`

        setCalculator(calculator => ({
            ...calculator,
            ...newCalculator
        }))
    }

    const theme = createTheme({
        palette: {
            primary: {
                main: '#40e561',
                contrastText: '#fff'
            }
        }
    })

    const convertWorkIntensity = (value) => {
        const dph = calculatorConfig.deliveriesPerHour
        const totalRange = dph.rangeTo - dph.rangeFrom

        return dph.rangeFrom + totalRange / 100 * value
    }

    const getLanguageLabel = (value, language) => {
        let languageLabel
        languageLevelMarks.forEach((languageLevelMark) => {
            languageLabel = value >= languageLevelMark.value ? languageNames[languageLevelMark.label] : languageLabel
        })
        return languageLabel ? languageLabel : `No ${language}`
    }

    const getLanguageLabelGerman = (value) => {
        return getLanguageLabel(value, 'German')
    }

    const getLanguageLabelEnglish = (value) => {
        return getLanguageLabel(value, 'English')
    }

    const getTotalExpenses = () => {
        let totalExpenses = 0
        calculatorConfig.expenses.forEach((expense) => {
            totalExpenses += expense.value
        })
        return totalExpenses
    }

    const setEarningPerDelivery = () => {
        Object.values(calculatorConfig.provider).forEach((provider) => {
            provider.languages.forEach((language) => {
                if (language.langMinLevel <= calculator.languageLevel[language.name].value) {
                    if (calculatorConfig.earningPerDelivery < provider.earningPerDelivery) {
                        setCalculatorConfig(calculatorConfig => ({
                            ...calculatorConfig,
                            earningPerDelivery: provider.earningPerDelivery
                        }))
                    }
                }
            })
        })
    }

    const calculate = () => {
        // Set earningPerDelivery in calculatorConfig to the highest possible value according to the language inputs
        setEarningPerDelivery()

        setStepState(steps.result)
        setResultState(resultState => {
            let result = {}
            result.amountOfDeliveries = calculatorConfig.workingHours * convertWorkIntensity(calculator.workIntensity)
            result.totalEarnings = calculatorConfig.earningPerDelivery * result.amountOfDeliveries
            result.totalExpenses = getTotalExpenses()
            result.nettoEarnings = result.totalEarnings - result.totalExpenses

            result.commission = []
            calculatorConfig.commission.forEach(commission => {
                if (result.nettoEarnings >= commission.rangeFrom) {
                    result.commission.push({
                        ...commission,
                        commission: ((result.nettoEarnings < commission.rangeTo ? result.nettoEarnings :
                            (commission.rangeTo ? commission.rangeTo : result.nettoEarnings)) - commission.rangeFrom)
                            / 100 * commission.percentage
                    })
                }
            })

            result.totalCommission = 0
            result.commission.forEach(commission => {
                result.totalCommission += commission.commission
            })

            result.salary = result.nettoEarnings - result.totalCommission
            result.tips = result.amountOfDeliveries * calculatorConfig.tipPerDelivery
            result.result = result.salary + result.tips

            return {
                ...resultState,
                ...result
            }
        })
    }

    const validateInputs = () => {
        let newNameError = candidate.name.length <= 3 ? true : false
        if (nameError !== newNameError) {
            setNameError(newNameError)
        }
        let newMailError = !mailValidator.validate(candidate.mail) ? true : false
        if (mailError !== newMailError) {
            setMailError(newMailError)
        }

        let newPhoneError = candidate.phone.length < 5 ? true : false
        if (phoneError !== newPhoneError) {
            setPhoneError(newPhoneError)
        }

        return (!newNameError && !newMailError && !newPhoneError)
    }

    let correctStep
    switch (stepState) {
        default:
        case steps.calculator:
            correctStep = (
                <Box sx={{ width: '100%' }}>
                    <div className="info">
                        <h2>Calculate your Income</h2>
                        Adjust these 3 Sliders to see how much you can earn, as easy as that!
                    </div>
                    <div className="sliderLabel">
                        <StaticImage
                            alt="Be a Happy rider!"
                            src="../images/de.png"
                            width={50}
                        />
                        <div className="name">How well do you speak german?</div>
                    </div>
                    <Slider
                        aria-labelledby="german"
                        step={null}
                        scale={getLanguageLabelGerman}
                        value={calculator.languageLevel.de.value}
                        marks={languageLevelMarks} aria-label="Default"
                        valueLabelDisplay="auto"
                        onChange={(e, val) => { handleLanguageChange(val, 'de') }} />

                    <div className="sliderLabel">
                        <StaticImage
                            alt="Be a Happy rider!"
                            src="../images/en.png"
                            width={50}
                        />
                        <div className="name">How well do you speak english?</div>
                    </div>
                    <Slider
                        aria-labelledby="english"
                        step={null}
                        scale={getLanguageLabelEnglish}
                        value={calculator.languageLevel.value}
                        marks={languageLevelMarks}
                        aria-label="Default"
                        valueLabelDisplay="auto"
                        onChange={(e, val) => { handleLanguageChange(val, 'en') }} />

                    <div className="sliderLabel">
                        <StaticImage
                            alt="Be a Happy rider!"
                            src="../images/reward.png"
                            width={50}
                        />
                        <div className="name">Work Intensity</div>
                    </div>
                    <Slider
                        aria-labelledby="work-intensity"
                        value={calculator.workIntensity}
                        marks={workIntensityMarks}
                        aria-label="Default"
                        valueLabelDisplay="off"
                        onChange={(e, val) => {
                            setCalculator(calculator => {
                                return {
                                    ...calculator,
                                    workIntensity: val
                                }
                            })
                        }} />

                    <Button variant="contained" disabled={calculationDisabled} size="large" sx={{ cursor: 'pointer' }} onClick={calculate}>Calculate</Button>
                </Box>
            )
            break

        case steps.result:
            correctStep = (
                <>
                    <Box id="loading" sx={{ width: '100%', textAlign: 'center', display: 'flex' }}>
                        <div>
                            <StaticImage
                                alt="Be a Happy rider!"
                                src="../images/rider.png"
                                width={150}
                            />
                            <Box sx={{ width: 150, margin: '10px auto' }}>
                                <LinearProgress sx={{ height: 20 }} />
                            </Box>
                        </div>
                    </Box>

                    <Box id="result" sx={{ width: '100%' }}>
                        <div className="info">
                            <h2>See how much you can earn!</h2>
                            Based on your given language skills and work intensity:
                        </div>
                        <table>
                            <tbody>
                                <tr>
                                    <td><strong>Total Earnings</strong></td>
                                    <td><Currency>{resultState.totalEarnings}</Currency></td>
                                </tr>
                                <tr>
                                    <td colSpan={2}><strong>- Expenses</strong></td>
                                </tr>
                                {calculatorConfig.expenses.map(({ label, value }) => (
                                    <tr key={label}>
                                        <td style={{ textAlign: 'right' }}>{label}</td>
                                        <td><Currency>{value}</Currency></td>
                                    </tr>
                                ))}
                                <tr>
                                    <td colSpan={2} style={{ borderTop: '1px solid black' }}></td>
                                </tr>
                                <tr>
                                    <td><strong>Netto Earnings</strong></td>
                                    <td><Currency>{resultState.nettoEarnings}</Currency></td>
                                </tr>
                                <tr>
                                    <td colSpan={2}><strong>- Commission</strong></td>
                                </tr>
                                {resultState.commission ?
                                    resultState.commission.map(({ rangeFrom, rangeTo, percentage, commission }) => (
                                        <tr key={percentage}>
                                            {rangeTo ?
                                                <td style={{ textAlign: 'right' }}>{rangeFrom} - {rangeTo} ({percentage}%)</td> :
                                                <td style={{ textAlign: 'right' }}>+{rangeFrom} ({percentage}%)</td>}
                                            <td><Currency>{commission}</Currency></td>
                                        </tr>
                                    )) : ''}
                                <tr>
                                    <td colSpan={2} style={{ borderTop: '1px solid black' }}></td>
                                </tr>
                                <tr>
                                    <td><strong>Your Salary</strong></td>
                                    <td><Currency>{resultState.salary}</Currency></td>
                                </tr>
                                <tr>
                                    <td><strong>+ Estimated Tips</strong></td>
                                    <td><Currency>{resultState.tips}</Currency></td>
                                </tr>
                                <tr>
                                    <td style={{ verticalAlign: 'bottom' }}><br /><strong>Your Total Income</strong></td>
                                    <td className="result"><br /><Currency>{resultState.result}</Currency></td>
                                </tr>
                            </tbody>
                        </table>
                        <Button variant="contained" size="large" sx={{ cursor: 'pointer' }} onClick={() => {
                            setStepState(steps.applyNow)
                        }}>Apply Now!</Button>
                        <div className='transportInfo'>
                            Per stay in Austria there are <Currency>{calculatorConfig.transfer}</Currency> additional transfer costs.
                        </div>
                    </Box>
                </>
            )
            break

        case steps.applyNow:
            if (formSent) {
                validateInputs()
            }

            correctStep = (
                <Box sx={{ width: '100%', textAlign: 'left' }}>
                    <div className="info">
                        <h2>Join our Team!</h2>
                        Be part of our motivated Rider-Family!
                    </div>

                    <TextField fullWidth label="Your Name" variant="filled" defaultValue={candidate.name} error={nameError}
                        onChange={e => setCandidate(candidate => ({ ...candidate, name: e.target.value }))} />

                    <TextField fullWidth label="Email" variant="filled" defaultValue={candidate.mail} error={mailError}
                        onChange={e => setCandidate(candidate => ({ ...candidate, mail: e.target.value }))} />

                    <TextField fullWidth label="Phone" variant="filled" defaultValue={candidate.phone} error={phoneError}
                        onChange={e => setCandidate(candidate => ({ ...candidate, phone: e.target.value }))} />

                    {(nameError || mailError || phoneError) &&
                        <Alert variant="filled" severity="error" style={{ marginTop: 30 }}>
                            Please fill out Name, Email and Phone correctly!
                        </Alert>
                    }

                    <LoadingButton
                        loading={loading}
                        loadingPosition="end"
                        endIcon={<SendIcon />}
                        variant="contained" size="large" sx={{ cursor: 'pointer' }} onClick={() => {

                            setFormSent(true)

                            if (validateInputs()) {

                                setLoading(true)

                                axios.post(process.env.SERVER_URL, {
                                    candidate: candidate,
                                    calculator: calculator,
                                    result: resultState
                                }).then((response) => {
                                    if (response.data === 'OK') {
                                        setStepState(steps.thx)
                                    }
                                }, (error) => {
                                    console.log(error)
                                })
                            }
                        }}>Join Now!</LoadingButton>
                </Box >
            )
            break

        case steps.thx:
            correctStep = (
                <Box sx={{ width: '100%', margin: '50px 0' }}>
                    <h2>Thank You!</h2>
                    We will contact you soon.
                </Box>
            )
            break
    }

    return (
        <ThemeProvider theme={theme}>
            <div id="calculator">
                {correctStep}
            </div>
        </ThemeProvider >
    )
}

export default Calculator